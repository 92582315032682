/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@use "gfx-theme.scss" as gfx-theme;
@tailwind base;

h1 {
  @apply text-2xl;
}
h2 {
  @apply text-xl;
}
h3 {
  @apply text-lg;
}

@tailwind components;
@tailwind utilities;

@include mat.core();

:root {
  @include mat.all-component-themes(gfx-theme.$light-theme);
}

.dark {
  @include mat.all-component-colors(gfx-theme.$dark-theme);
}

.gfx-button-primary {
  @include mat.button-color(gfx-theme.$light-theme, $color-variant: primary);
}

.dark .gfx-button-primary {
  @include mat.button-color(gfx-theme.$dark-theme, $color-variant: primary);
}

.gfx-button-secondary {
  @include mat.button-color(gfx-theme.$light-theme, $color-variant: secondary);
}

.dark .gfx-button-secondary {
  @include mat.button-color(gfx-theme.$dark-theme, $color-variant: secondary);
}

.gfx-button-error {
  @include mat.button-color(gfx-theme.$light-theme, $color-variant: error);
}

.ba-color {
  color: #fec424;
}

.bb-color {
  color: #cadf87;
}

.bc-color {
  color: #817b00;
}

.bp-color {
  color: #fe0000;
}

html body {
  height: 100%;
}

/* the real use-case behind this was that I couldn't figure
	out a way for the contents of the auto-complete search
	options to take up the full width of the <mat-select> area */
.mdc-list-item__primary-text {
  width: 100%;
}
